<template>
  <div class="list">
    <div class="article_form" style="padding-bottom:100px">
      <div class="form_submit">
        <div class="form_submit_box">
          <div class="form_submit_title"><div class="form_submit_title_1"><h3>会员信息</h3></div></div>
          <div class="form_submit_cont">
            <ul>
              <li><div class="left_title import">手机号码：</div><input type="text" v-model="huiyuanObj.phone" maxlength="11" @blur="checkUser"/><i :class="userErrClass" :title="userErr"></i></li>
              <li><div class="left_title import">会员姓名：</div><input type="text" v-model="huiyuanObj.xingming" :disabled="iszijiren" maxlength="10"/></li>
              <li><div class="left_title">性别：</div>
                <select v-model="huiyuanObj.xingbie" :disabled="iszijiren">
                  <option value="女">女</option>
                  <option value="男">男</option>
                </select>
              </li>
              <li><div class="left_title">信息通知：</div>
                <select v-model="huiyuanObj.tongzhileixing" :disabled="iszijiren||$store.state.User.daoqishijian<0">
                  <option v-for="item in tongzhileixing" :key="item.id" :value="item.id">{{item.name}}</option>
                </select>
                <img src="@/assets/images/vip.png" class="vip">
              </li>
              
            </ul>
            <ul>
              <li><div class="left_title">会员生日：</div><input type="date" v-model="huiyuanObj.shengri" :disabled="iszijiren"/></li>
              <li><div class="left_title">会员范围：</div>
                <label><input type="radio" v-model="huiyuanObj.huiyuanfanwei" value="1" :disabled="iszijiren||$store.state.User.daoqishijian<0"/>连锁</label>
                <label><input type="radio" v-model="huiyuanObj.huiyuanfanwei" value="2" :disabled="iszijiren||$store.state.User.daoqishijian<0"/>单店</label>
                <img src="@/assets/images/vip.png" class="vip">
              </li>
              <li><div class="left_title">专属客服：</div>
                <select v-model="huiyuanObj.zhuanshukefuId" @change="changezhuanshukefuName" :disabled="iszijiren||$store.state.User.daoqishijian<0">
                  <option v-for="item in EmpList" :value="item.id" :key="item.id">{{item.name}}</option>
                </select>
                <img src="@/assets/images/vip.png" class="vip">
              </li>
            </ul>
            <ul>
              <li><div class="left_title">会员卡号：</div><input type="text" v-model="huiyuanObj.vipcode" :disabled="iszijiren" maxlength="30"/></li>
              <li><div class="left_title">车牌号：</div><input type="text" v-model="huiyuanObj.chepaihao" :disabled="iszijiren" maxlength="10"/></li>
              <li><div class="left_title">备注信息：</div><textarea v-model="huiyuanObj.info" :disabled="iszijiren"></textarea></li>
            </ul>
          </div>
        </div>
        <div class="form_submit_box">
          <div class="form_submit_title"><div class="form_submit_title_1"><h3>开通信息</h3></div></div>
          <div class="form_submit_cont">
            <ul>
              <li><div class="left_title">选择套餐：</div>
                <LoyiSelectVue :width="200" :Uldata="packagesList" :sopIds="kiatongObj.packageId" :openWindow="true" @resData="LoyiBackDataForPackage" @windowOpen="addPackageId" ref="select_package">
                  <div class="meg">
                    <h1>选择套餐</h1>
                    <div class="meg_s">
                      <div class="meg_s_1">
                        <div class="meg_s_1_1">
                          <p>套餐名称：</p>
                          <input type="text" v-model="form.packageName" @keyup="filterPackage">
                        </div>
                        <div class="meg_s_1_1">
                          <p>携带会员等级：</p>
                          <select v-model="form.VIPLVId" @change="filterPackage">
                            <option value="0">全部</option>
                            <option v-for="item in VIPLVList" :key="item.id" :value="item.id">{{item.VIPLVName}}</option>
                          </select>
                        </div>
                        <div class="meg_s_1_1">
                          <button type="button" @click="filterPackage">查询</button>
                        </div>
                        <div class="clear"></div>
                      </div>
                      <div class="meg_s_2">
                        <div :class="packageOBJ.packageId==item.id?'hover meg_list_one':'meg_list_one'" v-for="item in packagesList" :key="item.id" @click="selectPackage(item.id,item.packageName)">
                          <h3>{{item.packageName}}</h3>
                          <p><span>门店：</span>{{item.fenpeimendianName}}</p>
                          <p><span>规格：</span>{{item.guige}}元套餐</p>
                          <p><span>会员：</span>{{item.huiyuandengjiName}}</p>
                          <p><span>详情：</span>{{returnInfo(item)}}</p>
                          <p class="about"><span>[ {{item.chuangjianrenName}} ]</span><span>{{item.chuangjianshijian.substr(0,10)}}</span></p>
                          <div class="meg_list_one_select part_left_top"></div>
                          <div class="meg_list_one_select part_right_top"></div>
                          <div class="meg_list_one_select part_right_bottom"></div>
                          <div class="meg_list_one_select part_left_bottom"></div>
                        </div>
                        <div class="LoyiSelectNoData" v-if="packagesList.length==0">暂无数据</div>
                        <div class="clear"></div>
                      </div>
                    </div>
                    <div class="meg_c"><button type="button" class="off" @click="closdMeg('select_package')">取消</button><button type="button" class="sub" @click="addPackage('select_package')">确定</button></div>
                    <div class="meg_cld" @click="closdMeg('select_package')"><i class="icon-x"></i></div>
                  </div>
                </LoyiSelectVue>
              </li>
              <li><div class="left_title">会员等级：</div>
                <select v-model="kiatongObj.VIPLVId" @change="addVIPLVName">
                  <option :value="0">无等级</option>
                  <option v-for="item in VIPLVList" :value="item.id" :key="item.id">{{item.VIPLVName}}</option>
                </select>
              </li>
              <!-- <li><div class="left_title import">默认价格：</div>￥{{kiatongObj.guige}}</li> -->
              <li><div class="left_title import">实得金额：</div><input type="number" v-model.number="kiatongObj.deyue" @change="countZongjia"/></li>
            </ul>
            <ul>
              <li><div class="left_title">赠送金额：</div><input type="number" v-model.number="kiatongObj.zengsongyue"/></li>
              <li><div class="left_title">赠送积分：</div><input type="number" v-model.number="kiatongObj.dejifen"/></li>
              <li><div class="left_title">服务人员：</div>
                <LoyiSelectVue :width="200" :Uldata="EmpList" @resData="LoyiBackDataForEmp" :disabled="$store.state.User.daoqishijian<0?true:false"></LoyiSelectVue>
                &nbsp;<img src="@/assets/images/vip.png" class="vip">
              </li>
              <li v-for="item in kiatongObj.fuwurenyuan" :key="item.id">
                <div class="left_title">{{item.xingming}}：</div>
                <label><input type="number" v-model.number="item.tichengbaifenbi"/> %</label>
                <label><input type="number" v-model.number="item.tichengguding"/> ￥</label>
              </li>
            </ul>
            <ul>
              <li v-for="pac in kiatongObj.cishuList" :key="pac.id">
                <div class="left_title">次卡：</div>
                <select v-model="pac.xiangmuId" @change="cikaaddName($event,pac)" style="width:110px">
                  <option v-for="item in itemsList"  :key="item.id" :value="item.id">{{item.itemName}}</option>
                </select>
                <input type="number" v-model.number="pac.num" @change="countZongjia" style="width:60px"/> 次
                <select v-model="pac.youxiaoqi">
                  <option value="100">永久</option>
                  <option value="1">1年</option>
                  <option value="2">2年</option>
                  <option value="3">3年</option>
                  <option value="4">4年</option>
                  <option value="5">5年</option>
                  <option value="6">6年</option>
                  <option value="7">7年</option>
                  <option value="8">8年</option>
                  <option value="9">9年</option>
                  <option value="10">10年</option>
                  <option value="15">15年</option>
                  <option value="20">20年</option>
                  <option value="30">30年</option>
                </select>
              </li>
              <li><div class="left_title">次卡：</div><button style="margin:0" type="button" @click="addpackagecika">增加次卡</button></li>
            </ul>
          </div>
        </div>
        <div class="article_jz">
          <div class="article_jz_a">
            <div class="article_jz_a_1">
              <ul>
                <li :class="tab==1?'hover':''" @click="setTab(1)">项目</li>
                <li :class="tab==2?'hover':''" @click="setTab(2)">商品</li>
                <li :class="tab==3?'hover':''" @click="setTab(3)">已挂单</li>
              </ul>
            </div>
            <div class="article_jz_a_2" v-show="tab==1">
              <div class="article_jz_a_search"><input type="text" v-model="searchI" @input="searchList('itemsDaleiList')" placeholder="输入名称、价格可快速搜索" /></div>
              <div class="article_jz_a_2_scroll">
                <div class="article_jz_a_2_con" v-for="item in itemsDaleiList" :key="item.itemDaleiId">
                  <div class="qiehuan_title">{{item.itemDaleiName}}</div>
                  <div class="article_jz_a_2_1_zi" v-for="item2 in item.itemList" :key="item2.id">
                    <div class="article_jz_a_2_1" @click="LoyiBackDataForItems(item2)">
                    <h3>{{item2.itemName}}</h3>
                      <p class="import">{{getDFJiage(item2)}}</p>
                      <p>{{item2.isDis?'允许会员优惠':'禁止会员优惠'}} | {{item2.oncika==1?'允许次卡':'禁止次卡'}}</p>
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
            <div class="article_jz_a_2" v-show="tab==2">
              <div class="article_jz_a_search"><input type="text" v-model="searchP" @input="searchList('productList')" placeholder="输入名称、价格可快速搜索" /></div>
              <div class="article_jz_a_2_scroll">
                <div class="article_jz_a_2_con">
                  <div class="qiehuan_title">商品列表</div>
                  <div class="article_jz_a_2_1_zi" v-for="item in productList" :key="item.id">
                    <div class="article_jz_a_2_1" @click="LoyiBackDataForProduct(item)">
                      <h3>{{item.name}}</h3>
                      <p>{{item.isDis?'允许会员优惠':'禁止会员优惠'}}</p>
                      <p class="import">价格：{{item.chushoujiage}} 元</p>
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
            <div class="article_jz_a_2" v-show="tab==3">
              <div class="article_jz_a_2_scroll" @scroll="getPreMo" style="height:410px">
                <div class="article_jz_a_2_con">
                  <div class="qiehuan_title">已挂消费单</div>
                  <div class="qiehuan_title2"><label>与我相关 <input type="checkbox" v-model="guadanByMe" @change="getGuadanList(1)"/></label></div>
                  <div class="article_jz_a_2_1_zi langHeight" v-for="item in guadanList" :key="item.id">
                    <div class="article_jz_a_2_1 langHeight red" @click="addGuadan(item)">
                      <h3>{{item.id}} 号单</h3>
                      <p class="import" :title="item.itemNames">{{item.itemNames}}</p>
                      <p :title="item.userNames">{{item.userNames}}</p>
                      <p>挂单时间：{{getDateTime(item.shijian)}}</p>
                    </div>
                  </div>
                  <div class="clear"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="article_jz_b">
            <div class="article_jz_a_1">
              <ul>
                <li class="hover">消费明细<strong class="impor" v-if="guadanId">（{{guadanId}}号单）<i class="icon-x" @click="clearGuadanId"></i></strong></li>
              </ul>
            </div>
            <div class="article_jz_b_1">
              <div class="article_jz_b_1_1">
                <table class="drags_3">
                  <colgroup>
                    <col width="40px">
                    <col >
                    <col width="80px">
                    <col >
                    <col width="110px">
                    <col width="110px">
                    <col width="70px">
                    <col width="70px">
                    <col width="80px">
                    <col width="144px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th style="text-align:center">序号</th>
                      <th>名称</th>
                      <th style="text-align:center">数量</th>
                      <th style="text-align:center">服务人员</th>
                      <th style="text-align:center">次卡消费(次)</th>
                      <th style="text-align:center">单价 / 总价</th>
                      <th style="text-align:center">优惠</th>
                      <th style="text-align:center">应收</th>
                      <th style="text-align:center">实收</th>
                      <th>操作</th>
                    </tr>
                  </thead>
                  <tbody class="vip_user_list">
                    <tr v-for="(item,i) in jiezhangObj.itemsList" :key="item.id">
                      <td style="text-align:center">{{i+1}}</td>
                      <td>
                        <p><span class="blue">{{item.itemName}}</span></p>
                      </td>
                      <td style="text-align:center">
                        <p><input type="number" v-model.number="item.num" @change="tongyijisuan"></p>
                      </td>
                      <td style="text-align:center">
                        <div class="buttonGroup" v-if="$store.state.User.daoqishijian>=0">
                          <button type="button" class="pur" @click.stop="showLoyiEmpSelect(item)">{{getRenyuan(item.renyuanList)}}</button>
                        </div>
                        <img v-else src="@/assets/images/vip.png" class="vip">
                      </td>
                      <td style="text-align:center">
                        <p><strong class="impor">{{item.xiaofeicishu}}</strong></p>
                      </td>
                      <td style="text-align:center">
                        <p>{{item.defjiage}}</p>
                        <p><strong class="impor">{{parseFloat((item.defjiage*(item.num-item.xiaofeicishu)).toFixed(2))}}</strong></p>
                      </td>
                      <td style="text-align:center">
                        <p>-{{item.huiyuanyouhui}}</p>
                        <p><strong class="impor">-{{parseFloat((item.huiyuanyouhui*(item.num-item.xiaofeicishu)).toFixed(2))}}</strong></p>
                      </td>
                      <td style="text-align:center">
                        <p><strong class="impor">{{item.yingshou}}</strong></p>
                      </td>
                      <td style="text-align:center">
                        <p><input type="number" v-model.number="item.shishou" @change="countZongjia"></p>
                      </td>
                      <td>
                        <div class="buttonGroup">
                          <button v-if="$store.state.User.daoqishijian>=0" type="button" class="cal" @click="tongburenyuan(item,i)">同步人员</button>
                          <button type="button" class="err" @click="delItem(i)">删除</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="article_jz_b_2">
              <div class="article_jz_b_2_1" v-if="jiezhangObj.xiaofeicishu&&jiezhangObj.xiaofeicishu.length">
                <div class="article_jz_b_2_1_title">消费次卡</div>
                <div class="article_jz_b_2_1_1" >
                  <p v-for="item in jiezhangObj.xiaofeicishu" :key="item.id" v-show="item.num"><span class="article_left_title">{{item.xiangmuName}}</span> × {{item.num}} 次</p>
                </div>
              </div>
              <div class="article_jz_b_2_1">
                <div class="article_jz_b_2_1_title">消费金额</div>
                <div class="article_jz_b_2_1_2"><span class="article_left_title">总计金额：</span><strong class="zhifu_jianhao"></strong>￥<strong>{{jiezhangObj.zongjijine}}</strong></div>
                <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">优惠合计：</span><strong class="zhifu_jianhao">-</strong>￥<strong>{{jiezhangObj.huiyuanyouhui}}</strong></div>
                <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">应付金额：</span><strong class="zhifu_jianhao"></strong>￥<strong>{{jiezhangObj.yingfukuan}}</strong></div>
                <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">实付金额：</span><strong class="zhifu_jianhao"></strong><input :disabled="jiezhangObj.itemsList.length==0" type="number" v-model.number="jiezhangObj.shifukuan"></div>
                <div class="article_jz_b_2_1_2"><span class="article_left_title">支付方式：</span><strong class="zhifu_jianhao"></strong>
                  <select v-model="jiezhangObj.zhifufangshi">
                    <option value="1">会员扣款</option>
                  </select>
                </div>
              </div>
              <div class="article_jz_b_2_1" v-if="shishoukuanObj.chae">
                <div class="article_jz_b_2_1_title">另收费</div>
                <div class="article_jz_b_2_1_2"><span class="article_left_title">差额：</span><strong class="zhifu_jianhao"></strong>￥<strong>{{shishoukuanObj.chae}}</strong></div>
                <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">扣除优惠：</span><strong class="zhifu_jianhao">+</strong>￥<strong>{{shishoukuanObj.kouhuiyuanyouhui}}</strong></div>
                <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">应付金额：</span><strong class="zhifu_jianhao"></strong>￥<strong>{{shishoukuanObj.yingfujine}}</strong></div>
                <div class="article_jz_b_2_1_2 zhifu_now"><span class="article_left_title">实付金额：</span><strong class="zhifu_jianhao"></strong><input type="number" v-model.number="shishoukuanObj.shifujine" @change="lingfuChange"></div>
              </div>
              <div class="article_jz_b_2_1">
                <div class="article_jz_b_2_1_title">结账备注</div>
                <div class="article_jz_b_2_1_1" style="text-align:center; "><textarea v-model="jiezhangObj.fuwuinfo"></textarea></div>
              </div>
            </div>
          </div>
        </div>
        <div class="form_submit_bottom">
          <div class="form_submit_bottom_box">
              <div class="form_submit_cont">
                <ul>
                  <li><div class="left_title import zhifu_now">应收金额：</div>￥<strong class="import">{{kiatongObj.yingshou}}</strong></li>
                </ul>
                <ul>
                  <li :class="shishoukuanBY0"><div class="left_title import">实收金额：</div><input type="number" v-model.number="kiatongObj.shishoukuan" class="now_zhifujine"/> 元</li>
                </ul>
                <ul>
                  <li><div class="left_title">支付方式：</div>
                    <select v-model="kiatongObj.zhifufangshi">
                      <option :value="item.id" v-for="item in zffsList" :key="item.id">{{item.name}}</option>    
                    </select>
                  </li>
                </ul>
                <ul>
                  <li><div class="left_title">支付备注：</div><textarea v-model="kiatongObj.zhifuinfo"></textarea></li>
                </ul>
              </div>
            <div class="form_submit_button">
              <button class="settab" @click="subData" :disabled="subDataDis">开通</button>
              <button class="remole" @click="closedWindow">返回</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EmpSelect v-if="LoyiEmpSelectShow" @resetData="addrenyuan" @closeLoyiEmpSelect="LoyiEmpSelectShow=false" :EmpData="EmpData"></EmpSelect>
  </div>
</template>

<script>
import moment from 'moment'
import axios from '@/http/axios'
import LoyiSelectVue from '@/components/asse/LoyiSelect.vue'
import {zffsList,tongzhileixing} from '@/data/data'
import EmpSelect from '@/components/asse/empSelect.vue'
import {deepCopy,testStr,sortFn} from '@/utils/util'
import { mapMutations } from "vuex"
export default {
  name: 'ActivateVIP',
  data() {
    return {
      tab:1,
      subDataDis:false,
      searchI:'',
      searchP:'',
      guadanId:null,
      guadanTotal:0,
      guadanIndex:1,
      onGetGuadan:true,
      guadanByMe:false,
      zffsList:zffsList,
      tongzhileixing,
      chaoertanchuang:true,
      EmpData:{},
      LoyiEmpSelectShow:false,
      EmpList:[],
      VIPLVList:[],
      itemsDaleiList:[],
      itemsList:[],
      productList:[],
      productListCopy:[],
      packagesList:[],
      packagesListCopy:[],
      shareList:[],
      guadanList:[],
      userErrClass:'',
      userErr:'',
      iszijiren:false,
      huiyuanObj:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId?this.$store.state.User.fenpeimendianId:0,
        fenpeimendianName:this.$store.state.User.fenpeimendianName?this.$store.state.User.fenpeimendianName:'',
        xingming:'',
        phone:'',
        shengri:'',
        xingbie:'女',
        vipcode:'',
        zhuanshukefuId:0,
        huiyuanfanwei:this.$store.state.User.daoqishijian>=0?1:2,
        tongzhileixing:this.$store.state.User.daoqishijian>=0?3:1,
        zhuanshukefuName:'',
        chepaihao:'',
        info:'',
        shangyicikefuId:null,
        shangyicikefuName:'',
      },
      kiatongObj:{
        packageId:'',
        packageName:'',
        VIPLVId:0,
        VIPLVName:'',
        guige:0,
        deyue:0,
        zengsongyue:0,
        dejifen:0,
        fuwurenyuan:[],
        tichengbaifenbi:0,
        tichengguding:0,
        cishuList:[],
        yingshou:0,
        shishoukuan:0,
        zhifufangshi:2,
        zhifuinfo:''
      },
      jiezhangObj:{
        itemsList:[],
        xiaofeicishu:[],
        zongjijine:0,
        huiyuanyouhui:0,
        yingfukuan:0,
        shifukuan:0,
        zhifufangshi:1,
        fuwuinfo:'',
      },
      shishoukuanObj:{
        chae:0,
        kouhuiyuanyouhui:0,
        yingfujine:0,
        shifujine:0,
      },
      packageOBJ:{
        packageId:'',
        packageName:'',
      },
      form:{
        packageName:'',
        VIPLVId:0,
      },
      shishoukuanBY0:'',
    }
  },
  created() {
    this.init()
  },
  components: {LoyiSelectVue,EmpSelect},
  methods: {
    ...mapMutations(['addNav']),
    searchList(str){
      if(str=='itemsDaleiList'){
        let arr=this.itemsList.filter(e=>{
          if(e.itemName.indexOf(this.searchI)>=0) return true
          if(String(e.dfprice).indexOf(this.searchI)>=0)return true
          return e.gangweijiage.some(a=>{
            return String(a.lvdfprice).indexOf(this.searchI)>=0
          })
        })
        let dalei=arr.filter((e,i)=>{
          return arr.findIndex(a=>a.itemDaleiId==e.itemDaleiId)==i
        })
        if(dalei.length){
          this.itemsDaleiList=dalei.map(e=>{
            return {
              itemDaleiId:e.itemDaleiId,
              itemDaleiName:e.itemDaleiName,
              itemList:arr.filter(a=>a.itemDaleiId==e.itemDaleiId)
            }
          })
        }else{
          this.itemsDaleiList=[]
        }
      }else if(str=='productList'){
        this.productList=this.productListCopy.filter(e=>{
          return (String(e.chushoujiage).indexOf(this.searchP)>=0||e.name.indexOf(this.searchP)>=0)
        })
      }
    },
    clearGuadanId(){
      this.guadanId=null
    },
    lingfuChange(){this.kiatongObj.shishoukuan=parseFloat((this.kiatongObj.guige+this.shishoukuanObj.shifujine).toFixed(2))},
    tongyijisuan(){
      if(this.jiezhangObj.itemsList.length){
        this.jiezhangObj.itemsList.forEach(x=>{
          let {defjiage,oncika,cikaticheng,huiyuanyouhui,yingshou}=this.countItemPrice(x)
          x.defjiage=defjiage
          x.oncika=oncika
          x.cikaticheng=cikaticheng
          x.huiyuanyouhui=huiyuanyouhui
          x.yingshou=yingshou
          x.shishou=yingshou
        })
      }
      this.countZongjia()
    },
    countItemPrice(item){
      let {product,isDis,metDis,VIPlvPrice,VIPlvPriceList,renyuanList,gangweijiage,defObj,defjiage,oncika,cikaticheng,num,huiyuanyouhui,yingshou}=item
      let zhekou= this.VIPLVList.find(a=>a.id==this.kiatongObj.VIPLVId)
      if(product){
        if(isDis&&zhekou){
          switch(zhekou.disGrade){
            case 0:{
              huiyuanyouhui=Math.ceil((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 1:{
              huiyuanyouhui=Math.floor((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 2:{
              huiyuanyouhui=Math.round((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 3:{
              huiyuanyouhui=Math.round(((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)*100)/100
              break
            }
          }
          yingshou=parseFloat(((defjiage-huiyuanyouhui)*num).toFixed(2))
        }else{
          huiyuanyouhui=0
          yingshou=parseFloat((defjiage*num).toFixed(2))
        }
      }else if(isDis){
        let gangweiOBJ=null
        if(renyuanList.length){
          let def=gangweijiage.find(b=>b.ganglvId==renyuanList[0].jibieId)
          if(def){
            defjiage=def.lvdfprice
            oncika=def.lvoncika
            cikaticheng=def.lvcikaticheng
            gangweiOBJ=def
          }else{
            defjiage=defObj.dfprice
            oncika=defObj.oncika
            cikaticheng=defObj.cikaticheng
          }
        }else{
          defjiage=defObj.dfprice
          oncika=defObj.oncika
          cikaticheng=defObj.cikaticheng
        }
        if(metDis){
          if(gangweiOBJ){
            let gangweivip=gangweiOBJ.VIPlvPriceList.find(b=>b.VIPlvId==this.kiatongObj.VIPLVId)
            let ys=gangweivip?gangweivip.VIPlvPrice:gangweiOBJ.VIPlvdfprice||gangweiOBJ.lvdfprice
            huiyuanyouhui=parseFloat((defjiage-ys).toFixed(2))
            yingshou=parseFloat((ys*num).toFixed(2))
          }else{
            let itemvip=VIPlvPriceList.find(b=>b.VIPlvId==this.kiatongObj.VIPLVId)
            let ys=itemvip?itemvip.VIPlvPrice:VIPlvPrice||defObj.dfprice
            huiyuanyouhui=parseFloat((defjiage-ys).toFixed(2))
            yingshou=parseFloat((ys*num).toFixed(2))
          }
        }else if(zhekou){
          switch(zhekou.disGrade){
            case 0:{
              huiyuanyouhui=Math.ceil((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 1:{
              huiyuanyouhui=Math.floor((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 2:{
              huiyuanyouhui=Math.round((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 3:{
              huiyuanyouhui=Math.round(((defjiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)*100)/100
              break
            }
          }
          yingshou=parseFloat(((defjiage-huiyuanyouhui)*num).toFixed(2))
        }else{
          huiyuanyouhui=0
          yingshou=parseFloat((defjiage*num).toFixed(2))
        }
      }else{
        huiyuanyouhui=0
        yingshou=parseFloat((defjiage*num).toFixed(2))
      }
      return{defjiage,oncika,cikaticheng,huiyuanyouhui,yingshou}
    },
    tongburenyuan(item,i){
      if(item.renyuanList.length){
        this.jiezhangObj.itemsList.forEach((e,n)=>{
          if(n!=i){
            e.renyuanList=deepCopy(item.renyuanList).map(a=>{
              if(e.product){
                if(a.title=='主服'){
                  a.baifenbi=e.onebaifenbi
                  a.guding=e.oneguding
                }else if(a.title=='副服'){
                  a.baifenbi=e.twobaifenbi||0
                  a.guding=e.twoguding||0
                }else if(a.title=='从服'){
                  a.baifenbi=e.threebaifenbi||0
                  a.guding=e.threeguding||0
                }else{
                  a.baifenbi=a.baifenbi-(a.zhidingjiacheng?a.zhidingjiacheng.tichengbaifenbi:0)
                  a.guding=a.guding-(a.zhidingjiacheng?a.zhidingjiacheng.tichengguding:0)
                }
              }else{
                if(a.zhiding){
                  if(a.title=='主服'){
                    a.baifenbi=e.onebaifenbi+(a.zhidingjiacheng?a.zhidingjiacheng.tichengbaifenbi:0)
                    a.guding=e.oneguding+(a.zhidingjiacheng?a.zhidingjiacheng.tichengguding:0)
                  }else if(a.title=='副服'){
                    a.baifenbi=(e.twobaifenbi||0)+(a.zhidingjiacheng?a.zhidingjiacheng.tichengbaifenbi:0)
                    a.guding=(e.twoguding||0)+(a.zhidingjiacheng?a.zhidingjiacheng.tichengguding:0)
                  }else if(a.title=='从服'){
                    a.baifenbi=(e.threebaifenbi||0)+(a.zhidingjiacheng?a.zhidingjiacheng.tichengbaifenbi:0)
                    a.guding=(e.threeguding||0)+(a.zhidingjiacheng?a.zhidingjiacheng.tichengguding:0)
                  }
                }else{
                  if(a.title=='主服'){
                    a.baifenbi=e.onebaifenbi
                    a.guding=e.oneguding
                  }else if(a.title=='副服'){
                    a.baifenbi=e.twobaifenbi||0
                    a.guding=e.twoguding||0
                  }else if(a.title=='从服'){
                    a.baifenbi=e.threebaifenbi||0
                    a.guding=e.threeguding||0
                  }
                }
                
              }
              return a
            })
          }
        })
        this.tongyijisuan()
      }
    },
    delItem(i){
      this.jiezhangObj.itemsList.splice(i,1)
      this.countZongjia()
    },
    addrenyuan(e){
      this.EmpData.item.renyuanList=e
      if(this.EmpData.item.product) return 
      if(this.EmpData.item.renyuanList.length){
        let {renyuanList,gangweijiage}=this.EmpData.item
        let def=gangweijiage.find(b=>b.ganglvId==renyuanList[0].jibieId)
        if(def){
          this.EmpData.item.defjiage=def.lvdfprice
          this.EmpData.item.oncika=def.lvoncika
          this.EmpData.item.cikaticheng=def.lvcikaticheng
        }else{
          this.EmpData.item.defjiage=this.EmpData.item.defObj.dfprice
          this.EmpData.item.oncika=this.EmpData.item.defObj.oncika
          this.EmpData.item.cikaticheng=this.EmpData.item.defObj.cikaticheng
        }
      }else{
        this.EmpData.item.defjiage=this.EmpData.item.defObj.dfprice
        this.EmpData.item.oncika=this.EmpData.item.defObj.oncika
        this.EmpData.item.cikaticheng=this.EmpData.item.defObj.cikaticheng
      }
      this.tongyijisuan()
    },
    getRenyuan(r){
      if(Array.isArray(r)){
        let n=r.filter(e=>e.id)
        if(n.length){
          return n.map(e=>e.name).join("、")
        }else return "请选择"
        
      }else{
        return "请选择"
      }
    },
    showLoyiEmpSelect(item){
      this.EmpData.shareList=this.shareList
      this.EmpData.item=item
      this.LoyiEmpSelectShow=true
    },
    jisuancika(){
      if(this.kiatongObj.cishuList.length){
        let xfcishu=this.kiatongObj.cishuList.map(e=>{
          return {
            id:e.id,
            num:0,
            maxNum:e.num,
            xiangmuId:e.xiangmuId,
            xiangmuName:e.xiangmuName,
            youxiaoqi:e.youxiaoqi
          }
        }).sort((a,b)=>parseInt(a.youxiaoqi) - parseInt(b.youxiaoqi))
        this.jiezhangObj.itemsList.forEach(e=>{
          e.xiaofeicishu=0
          if(e.oncika==1){
            let ci=xfcishu.filter(a=>a.xiangmuId==e.id&&a.num<a.maxNum)
            if(ci.length){
              ci.forEach(b=>{
                if(e.xiaofeicishu<e.num){
                  let zengjiacishu=Math.min((e.num-e.xiaofeicishu),(b.maxNum-b.num))
                  e.xiaofeicishu+=zengjiacishu
                  e.yingshou=(e.defjiage*(e.num-e.xiaofeicishu))-(e.huiyuanyouhui*(e.num-e.xiaofeicishu))
                  e.shishoukuan=e.yingshou
                  e.shishou=e.yingshou
                  b.num+=zengjiacishu
                }
              })
            }
          }
        })
        this.jiezhangObj.xiaofeicishu=xfcishu
      }
    },
    countZongjia(){
      this.jisuancika()
      let zongjijine=0,huiyuanyouhui=0,yingfukuan=0,shifukuan=0
      this.jiezhangObj.itemsList.forEach(e=>{
        zongjijine+=(e.defjiage*(e.num-e.xiaofeicishu))
        huiyuanyouhui+=(e.huiyuanyouhui*(e.num-e.xiaofeicishu))
        yingfukuan+=e.yingshou
        shifukuan+=parseFloat(e.shishou)
      })
      this.jiezhangObj.zongjijine=parseFloat(zongjijine.toFixed(2))
      this.jiezhangObj.huiyuanyouhui=parseFloat(huiyuanyouhui.toFixed(2))
      this.jiezhangObj.yingfukuan=parseFloat(yingfukuan.toFixed(2))
      this.jiezhangObj.shifukuan=parseFloat(shifukuan.toFixed(2))
      if(this.kiatongObj.deyue+this.kiatongObj.zengsongyue<shifukuan){
        if(this.chaoertanchuang){
          this.$Mesg({content:`会员:${this.huiyuanObj.xingming} 的余额已不足以支付本次消费!`,errOrSuc: 'war'})
          this.chaoertanchuang=false
        }
        let zhekou= this.VIPLVList.find(a=>a.id==this.kiatongObj.VIPLVId)
        this.shishoukuanObj.chae=parseFloat((shifukuan-(this.kiatongObj.deyue+this.kiatongObj.zengsongyue)).toFixed(2))
        if(zhekou&&huiyuanyouhui>0){
          let koubili=1-(huiyuanyouhui/zongjijine)
          this.shishoukuanObj.kouhuiyuanyouhui=parseFloat(((this.shishoukuanObj.chae/koubili)-this.shishoukuanObj.chae).toFixed(2))
        }else{
          this.shishoukuanObj.kouhuiyuanyouhui=0
        }
        this.shishoukuanObj.yingfujine=parseFloat((this.shishoukuanObj.chae+this.shishoukuanObj.kouhuiyuanyouhui).toFixed(2))
        this.shishoukuanObj.shifujine=this.shishoukuanObj.yingfujine
        this.jiezhangObj.shifukuan=parseFloat((this.kiatongObj.deyue+this.kiatongObj.zengsongyue).toFixed(2))
        this.kiatongObj.yingshou=parseFloat((this.kiatongObj.guige+this.shishoukuanObj.yingfujine).toFixed(2))
        this.kiatongObj.shishoukuan=parseFloat((this.kiatongObj.guige+this.shishoukuanObj.shifujine).toFixed(2))

      }else{
        this.shishoukuanObj.chae=0
        this.shishoukuanObj.kouhuiyuanyouhui=0
        this.shishoukuanObj.yingfujine=0
        this.shishoukuanObj.shifujine=0
        this.kiatongObj.yingshou=this.kiatongObj.guige
        this.kiatongObj.shishoukuan=this.kiatongObj.guige
      }
    },
    getDFJiage(item){
      let dfArr=[]
      dfArr.push(item.dfprice)
      if(item.gangweijiage.length){
        item.gangweijiage.forEach(e=>{
          dfArr.push(e.lvdfprice)
        })
      }
      let Arr=sortFn(dfArr)
      return Arr.join('元/')+'元'
    },
    setTab(num){
      this.tab=num
      if(num==3){this.getGuadanList(1)}
    },
    guadanCount(arr){
      return arr.map(e=>{
        let item=null,defjg=0
        if(e.product){
          item=this.productList.find(a=>a.id==e.id)
          defjg=item.chushoujiage||0
        }else{
          item=this.itemsList.find(a=>a.id==e.id)
          defjg=item.dfprice||0
        }
        if(item){
          let {defjiage,oncika,cikaticheng,huiyuanyouhui,yingshou}=this.countItemPrice({
            product:e.product,
            isDis:item.isDis,
            metDis:item.metDis||0,
            VIPlvPrice:item.VIPlvPrice||null,
            VIPlvPriceList:item.VIPlvPriceList||[],
            renyuanList:e.renyuanList,
            gangweijiage:item.gangweijiage||[],
            defObj:{
              oncika:item.oncika||2,
              dfprice:defjg,
              cikaticheng:item.cikaticheng||0,
            },
            defjiage:defjg,
            oncika:item.oncika||2,
            cikaticheng:item.cikaticheng||0,
            num:e.num,
            huiyuanyouhui:0,
            yingshou:defjg,
          })
          return{
            id:item.id,
            itemName:item.name||item.itemName,
            product:e.product,
            num:e.num,
            renyuanList:e.renyuanList,
            onebaifenbi:item.onebaifenbi||item.tichengbaifenbi,
            oneguding:item.oneguding||item.tichengguding,
            twobaifenbi:item.twobaifenbi,
            twoguding:item.twoguding,
            threebaifenbi:item.threebaifenbi,
            threeguding:item.threeguding,
            baifenbifangshi:item.baifenbifangshi,
            oncika,
            gangweijiage:item.gangweijiage||[],
            huiyuanyouhui,        
            yingshou,
            shishou:yingshou,
            xiaofeicishu:0,
            cikaticheng,
            defjiage,
            chengben:item.chengben,
            isDis:item.isDis,
            metDis:item.metDis||0,
            VIPlvPrice:item.VIPlvPrice||null,
            VIPlvPriceList:item.VIPlvPriceList||[],
            defObj:{
              oncika:item.oncika,
              dfprice:item.dfprice,
              cikaticheng:item.cikaticheng,
            },
          }
        }else return {}
      })
    },
    addGuadan(item){
      const{id,fuwuinfo}=item
      axios.post('/VIP/getItemsById',{id}).then((res) => {
        if(res.code==5000){
          this.guadanId=id
          let items=JSON.parse(res.data.items)
          this.jiezhangObj.fuwuinfo=fuwuinfo
          this.jiezhangObj.itemsList=this.guadanCount(items)
          this.countZongjia()
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    setGuadanState(){
      axios.post('/VIP/setGuadanState',{guadanId:this.guadanId}).then((res) => {
        if(res.code!=5000){
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        } 
      })
    },
    submitData(){
      this.huiyuanObj.nowDeyue=this.kiatongObj.zengsongyue-this.jiezhangObj.shifukuan>=0?this.kiatongObj.deyue:this.kiatongObj.zengsongyue+this.kiatongObj.deyue-this.jiezhangObj.shifukuan
      this.huiyuanObj.nowZengsongyue=this.kiatongObj.zengsongyue-this.jiezhangObj.shifukuan>=0?this.kiatongObj.zengsongyue-this.jiezhangObj.shifukuan:0
      if(this.kiatongObj.cishuList.length>1){
        this.kiatongObj.cishuList=this.kiatongObj.cishuList.filter((e,i)=>{
          let index=this.kiatongObj.cishuList.findIndex(a=>(a.xiangmuId==e.xiangmuId&&a.youxiaoqi==e.youxiaoqi&&a.num>0))
          if(i==index) return true
          else if(index>=0){
            this.kiatongObj.cishuList[index].num+=e.num
            return false
          }else{
            return false
          }
        })
      }
      if(this.kiatongObj.fuwurenyuan.length){
        let baifenbi=0,guding=0
        this.kiatongObj.fuwurenyuan.forEach(e=>{baifenbi+=e.tichengbaifenbi;guding+=e.tichengguding})
        let isOrigin=baifenbi<=this.kiatongObj.tichengbaifenbi?guding<=this.kiatongObj.tichengguding?0:1:1
        this.kiatongObj.fuwurenyuan.forEach(e=>{e.isOrigin=isOrigin})
      }
      axios.post('/VIP/addVIPUser',{
        hy:this.huiyuanObj,
        kt:this.kiatongObj,
        jz:this.jiezhangObj,
        ss:this.shishoukuanObj,
      }).then((res) => {
        this.subDataDis=false
        if(res.code==5000){
          if(this.guadanId) this.setGuadanState()
          this.$router.push({path:"/detail",query:{UserId:res.data.UserId,XFJL:res.data.XFJL,CZJL:res.data.CZJL}})
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
      this.subDataDis=false
    },
    subDataBYRY(){
      if(this.jiezhangObj.itemsList.length){
        if(this.jiezhangObj.itemsList[0].renyuanList&&this.jiezhangObj.itemsList[0].renyuanList.length){
          this.huiyuanObj.shangyicikefuId=this.jiezhangObj.itemsList[0].renyuanList[0].id||null
          this.huiyuanObj.shangyicikefuName=this.jiezhangObj.itemsList[0].renyuanList[0].name
        }
        if(this.$store.state.User.daoqishijian<0||this.jiezhangObj.itemsList.every(e=>{return e.renyuanList.some(b=>{return b.id})})){
          this.submitData()
        }else{
          this.$trueOrFalse({
            title: '重要提示',
            content:'消费项中有未选择服务人员的项目，确定继续结账？(如已修改实收，选择服务人员后，实收会变动，请重新更改！)',
            torf: true,
            suc:()=>{
              this.submitData()
            },
            err:()=>{
              this.subDataDis=false
            }
          })
        }
      }else{
        this.submitData()
      }
    },
    subData(){
      if(testStr(this.huiyuanObj.xingming)){
        this.$Mesg({content:'会员名不能为空！',errOrSuc: 'war',})
        return false
      }
      if(testStr(this.huiyuanObj.phone)){
        this.$Mesg({content:'手机号不能为空！',errOrSuc: 'war',})
        return false
      }
      if(this.iszijiren){
        this.$Mesg({content:'手机号码已在本店注册！',errOrSuc: 'err',})
        return false
      }
      this.subDataDis=true
      if(this.kiatongObj.fuwurenyuan.length==0&&this.$store.state.User.daoqishijian>=0){
        this.$trueOrFalse({
          title: '提示信息',
          content:'充值服务人未选择，是否确认继续结账？(请注意！自由充值默认无提成)',
          torf: true,
          suc:()=>{
            if(!this.kiatongObj.shishoukuan){
              this.shishoukuanBY0='ImportMsg'
              this.$trueOrFalse({
                title: '重要提示',
                content:'充值实收金额为0，确定继续操作？(自由充值请最后填写实收金额，否则会因消费项目等自动计算导致自动修改实收金额)',
                torf: true,
                suc:()=>{
                  this.subDataBYRY()
                },
                err:()=>{
                  this.subDataDis=false
                }
              })
            }else{
              this.subDataBYRY()
            }
          },
          err:()=>{
            this.subDataDis=false
          }
        })
      }else{
        if(!this.kiatongObj.shishoukuan){
          this.shishoukuanBY0='ImportMsg'
          this.$trueOrFalse({
            title: '重要提示',
            content:'充值实收金额为0，确定继续操作？(自由充值请最后填写实收金额，否则会因消费项目等自动计算导致自动修改实收金额)',
            torf: true,
            suc:()=>{
              this.subDataBYRY()
            },
            err:()=>{
              this.subDataDis=false
            }
          })
        }else{
          this.subDataBYRY()
        }
      }
      
    },
    closedWindow(){
      this.$router.back()
    },
    filterPackage(){
      let {packageName,VIPLVId}=this.form
      this.packagesList=this.packagesListCopy.filter(e=>{
       return (!packageName||e.name.indexOf(packageName)>=0)&&(VIPLVId==0||e.huiyuandengjiId==VIPLVId)
      })
    },
    checkUser(){
      if(this.huiyuanObj.phone){
        this.iszijiren=false
        if(this.huiyuanObj.phone.length>0){
          axios.post('/VIP/checkuser',{phone:this.huiyuanObj.phone}).then((res) => {
            if(res.code===5000){
              if(res.data){
                let index=res.data.findIndex(e=>e.fenpeimendianId==this.huiyuanObj.fenpeimendianId)
                if(index>=0){
                  this.iszijiren=true
                  this.$trueOrFalse({
                    title: '重要提示',
                    content:`手机号码“${this.huiyuanObj.phone}”已经在本店注册，请跳转充值页面`,
                    torf: true,
                    suc:()=>{
                      this.addNav({name:'会员充值',path:'/rechargeMember'})
                      this.$router.push({path:"/rechargeMember",query:{id:res.data.id}})
                    }          
                  })
                  this.userErrClass="icon-slash"
                  this.userErr='已存在'
                }else{
                  this.$trueOrFalse({
                    title: '重要提示',
                    content:`手机号码“${this.huiyuanObj.phone}”已经在（连锁店）注册，是否跳转充值页面充值？`,
                    torf: true,
                    suc:()=>{
                      this.addNav({name:'会员充值',path:'/rechargeMember'})
                      this.$router.push({path:"/rechargeMember",query:{id:res.data.id}})
                    },
                    err:()=>{
                      this.userErrClass="icon-check"
                      this.userErr=''
                      this.huiyuanObj.id=null
                    }          
                  })
                }
              }else{
                this.userErrClass="icon-check"
                this.userErr=''
                this.huiyuanObj.id=null
              }
            }else{
              this.$Mesg({content: res.msg,errOrSuc: 'err'})
            }
          })
        }else{
          this.userErrClass="icon-slash"
          this.userErr='手机号码不能为空'
          this.$Mesg({content:"手机号码不能为空",errOrSuc: 'war'})
        }
      }
    },
    changezhuanshukefuName(e){
      let empObj=this.EmpList.find(a=>a.id==e.target.value)
      if(empObj) this.huiyuanObj.zhuanshukefuName=empObj.name
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    init(){
      if(this.$store.state.User.daoqishijian>=0){
        this.getShareList()
        this.getEmpList()
      }
      this.getVIPLVList()
      this.getItemList()
      this.getpackagesList()
      this.getProductList()
    },
    cikaaddName(e,obj){
      obj.xiangmuName=this.itemsList.find(a=>a.id==e.target.value).itemName
    },
    addVIPLVName(){
      if(this.kiatongObj.VIPLVId==0){
        this.kiatongObj.VIPLVName="无等级"
      }else{
        let vipLV=this.VIPLVList.find(e=>e.id==this.kiatongObj.VIPLVId)
        if(vipLV) this.kiatongObj.VIPLVName = vipLV.VIPLVName
      }
      this.tongyijisuan()
    },
    LoyiBackDataForItems(item){
      let {defjiage,oncika,cikaticheng,huiyuanyouhui,yingshou}=this.countItemPrice({
        product:false,
        isDis:item.isDis,
        metDis:item.metDis,
        VIPlvPrice:item.VIPlvPrice,
        VIPlvPriceList:item.VIPlvPriceList,
        renyuanList:[],
        gangweijiage:item.gangweijiage,
        defObj:{
          oncika:item.oncika,
          dfprice:item.dfprice,
          cikaticheng:item.cikaticheng,
        },
        defjiage:item.dfprice,
        oncika:item.oncika,
        cikaticheng:item.cikaticheng,
        num:1,
        huiyuanyouhui:0,
        yingshou:item.dfprice,
      })
      let newitemData={
        id:item.id,
        itemName:item.itemName,
        num:1,
        defObj:{
          oncika:item.oncika,
          dfprice:item.dfprice,
          cikaticheng:item.cikaticheng,
        },
        renyuanList:[],
        onebaifenbi:item.onebaifenbi,
        oneguding:item.oneguding,
        twobaifenbi:item.twobaifenbi,
        twoguding:item.twoguding,
        threebaifenbi:item.threebaifenbi,
        threeguding:item.threeguding,
        cikaticheng,
        oncika,
        gangweijiage:item.gangweijiage,
        huiyuanyouhui,        
        yingshou,
        shishou:yingshou,
        xiaofeicishu:0,
        defjiage,
        isDis:item.isDis,
        metDis:item.metDis,
        VIPlvPrice:item.VIPlvPrice,
        VIPlvPriceList:item.VIPlvPriceList,
        percentagePlan:item.percentagePlan,
        allBFB:item.allBFB,
        allGD:item.allGD,
        costBFB:item.costBFB,
        costGD:item.costGD,
      }
      this.jiezhangObj.itemsList.push(newitemData)
      this.countZongjia()
    },
    LoyiBackDataForProduct(item){
      let huiyuanyouhui=0,yingshou=item.chushoujiage
      if(item.isDis){
        let zhekou= this.VIPLVList.find(a=>a.id==this.kiatongObj.VIPLVId)
        if(zhekou){
          switch(zhekou.disGrade){
            case 0:{
              huiyuanyouhui=Math.ceil((item.chushoujiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 1:{
              huiyuanyouhui=Math.floor((item.chushoujiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 2:{
              huiyuanyouhui=Math.round((item.chushoujiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)
              break
            }
            case 3:{
              huiyuanyouhui=Math.round(((item.chushoujiage-zhekou.VIPDiscountsReg)*(zhekou.VIPDiscountsPer/100)+zhekou.VIPDiscountsReg)*100)/100
              break
            }
          }
          yingshou=parseFloat((item.chushoujiage-huiyuanyouhui).toFixed(2))
        }
      }
      let newProductData={
        id:item.id,
        itemName:item.name,
        product:true,
        num:1,
        renyuanList:[],
        onebaifenbi:item.tichengbaifenbi,
        baifenbifangshi:item.baifenbifangshi,
        oneguding:item.tichengguding,
        oncika:2,
        gangweijiage:[],
        huiyuanyouhui,        
        yingshou,
        shishou:yingshou,
        xiaofeicishu:0,
        cikaticheng:0,
        defjiage:item.chushoujiage,
        chengben:item.chengben,
        isDis:item.isDis,
      }
      this.jiezhangObj.itemsList.push(newProductData)  
      this.countZongjia()
    },
    LoyiBackDataForPackage(){
      this.kiatongObj.packageId=''
      this.kiatongObj.packageName=''
    },
    LoyiBackDataForEmp(e){
      const {thisData,newData,delData}=e
      if(newData){
        if(thisData.length&&thisData[0].id==newData.id){
          this.kiatongObj.fuwurenyuan.push({
            id:newData.id,
            xingming:newData.name,
            tichengbaifenbi:this.kiatongObj.tichengbaifenbi,
            tichengguding:this.kiatongObj.tichengguding
          })
        }else{
          this.kiatongObj.fuwurenyuan.push({
            id:newData.id,
            xingming:newData.name,
            tichengbaifenbi:0,
            tichengguding:0
          })
        }
      }
      if(delData){
        let index = this.kiatongObj.fuwurenyuan.findIndex(a=>a.id==delData.id)
        if(index>=0)this.kiatongObj.fuwurenyuan.splice(index,1)
      } 
    },
    addpackagecika(){
      this.kiatongObj.cishuList.push({
        num:0,
        xiangmuId:null,
        xiangmuName:'',
        youxiaoqi:100,
      })
    },
    addFormKaitongxinxi(pac){
      const {cishuList,dejifen,deyue,guige,huiyuandengjiId,huiyuandengjiName,zengsongyue,tichengguding,tichengbaifenbi} =pac
      this.kiatongObj.dejifen=dejifen
      this.kiatongObj.deyue=deyue
      this.kiatongObj.guige=guige
      this.kiatongObj.yingshou=guige+(this.shishoukuanObj.chae?this.shishoukuanObj.yingfujine:0)
      this.kiatongObj.shishoukuan=guige+(this.shishoukuanObj.chae?this.shishoukuanObj.shifujine:0),
      this.kiatongObj.VIPLVId=huiyuandengjiId
      this.kiatongObj.VIPLVName=huiyuandengjiName
      this.kiatongObj.zengsongyue=zengsongyue
      this.kiatongObj.tichengbaifenbi=tichengbaifenbi
      this.kiatongObj.tichengguding=tichengguding
      this.kiatongObj.cishuList=deepCopy(cishuList)
      if(this.kiatongObj.fuwurenyuan.length){
        this.kiatongObj.fuwurenyuan[0].tichengbaifenbi=tichengbaifenbi
        this.kiatongObj.fuwurenyuan[0].tichengguding=tichengguding
      }
      this.tongyijisuan()
    },
    addPackage(str){
      if(this.packageOBJ.packageId){
        this.kiatongObj.packageId=String(this.packageOBJ.packageId)
        this.kiatongObj.packageName=this.packageOBJ.packageName
        let pac=this.packagesList.find(e=>e.id==this.kiatongObj.packageId)
        if(pac)this.addFormKaitongxinxi(pac)
        this.closdMeg(str)
      }else{
        this.$Mesg({content: '未选择套餐',errOrSuc: 'war',})
      }
    },
    addPackageId(){
      this.packageOBJ.packageId=this.kiatongObj.packageId
      this.packageOBJ.packageName=this.kiatongObj.packageName
    },
    selectPackage(id,name){
      this.packageOBJ.packageId=String(id)
      this.packageOBJ.packageName=name
    },
    returnInfo(obj){
      const{guige,deyue,dejifen,zengsongyue,cishuList}=obj
      let str=`充 ${guige}元 ${deyue?'得 '+deyue+'元':''}${dejifen?' 得 '+dejifen+'积分':''}${zengsongyue?' 送 '+zengsongyue+'元':''}`
      cishuList.forEach(e=>{
        const {num,xiangmuName}=e
        str+=`得 ${xiangmuName} ${num}次`
      })
      return str
    },
    closdMeg(str){
      this.$refs[str].onWindow=false
      this.packageOBJ.packageId=''
      this.packageOBJ.packageName=''
      this.form.packageName=''
      this.form.VIPLVId=0
      this.packagesList=this.packagesListCopy
    },
    getpackagesList(){
      axios.post('/packages/list',{fenpeimendianId:this.huiyuanObj.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.packagesList=res.data.map(e=>{
            return {
              ...e,
              name:e.packageName,
            }
          })
          this.packagesListCopy=deepCopy(this.packagesList)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getItemList(){
      axios.post('/item/list',{fenpeimendianId:this.huiyuanObj.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          let {data,gangweijiage,VIPlvPriceList}=res
          gangweijiage.forEach(e=>{
            e.VIPlvPriceList=VIPlvPriceList.filter(a=>a.ganglvId==e.ganglvId&&a.itemId==e.itemId)
          })
          data.forEach(e=>{
            e.VIPlvPriceList=VIPlvPriceList.filter(a=>a.ganglvId==0&&e.id==a.itemId)
            e.gangweijiage=gangweijiage.filter(a=>a.itemId==e.id)
          })
          this.itemsList=data
          let dalei=this.itemsList.filter((e,i)=>{
            return this.itemsList.findIndex(a=>a.itemDaleiId==e.itemDaleiId)==i
          })
          if(dalei.length){
            this.itemsDaleiList=dalei.map(e=>{
              return {
                itemDaleiId:e.itemDaleiId,
                itemDaleiName:e.itemDaleiName,
                itemList:this.itemsList.filter(a=>a.itemDaleiId==e.itemDaleiId)
              }
            })
          }
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.huiyuanObj.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.EmpList=res.data.map(e=>{
            return{
              id:e.id,
              name:e.xingming,
              jibieId:e.jibieId,
              jibieName:e.jibieName,
            }
          })
          this.EmpData.empList=this.EmpList
          this.EmpData.jibieList=this.EmpList.filter((e,i)=>{
            return this.EmpList.findIndex(a=>a.jibieId==e.jibieId)==i
          })
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getVIPLVList(){
      axios.post('/viplv/list',{fenpeimendianId:this.huiyuanObj.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.VIPLVList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getProductList(){
      axios.post('/product/list',{fenpeimendianId:this.huiyuanObj.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.productList=res.data
          this.productListCopy=deepCopy(this.productList)
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getShareList(){
      axios.post('/share/list',{fenpeimendianId:this.huiyuanObj.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.shareList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getPreMo(e){
      let con=e.target
      if(this.onGetGuadan&&(this.guadanIndex-1)*20<this.guadanTotal&&con.clientHeight+con.scrollTop+10>=con.scrollHeight){
        this.onGetGuadan=false
        this.getGuadanList(this.guadanIndex)
      }
    },
    getGuadanList(num){
      axios.post('/vip/getGuadanList',{fenpeimendianId:this.huiyuanObj.fenpeimendianId,guadanByMe:this.guadanByMe,pageIndex:num?num:1,state:1,pageSize:20}).then((res) => {
        if(res.code===5000){
          if(num==1){
            this.guadanList=res.data
            this.guadanIndex=1
          }else{
            this.guadanList.push(...res.data)
          }
          this.guadanTotal=res.total
          this.guadanIndex++
          this.onGetGuadan=true
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
